html,
body {
    padding: 0;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    scroll-behavior: smooth;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

#recommendations-error {
    display: none;
}

.highlight {
    background-color: #D69E2E;
    color: black;
    padding: 2px;
}
