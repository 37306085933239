.NavBarContainer {
    width: 100%;
    height: 132px;
    background-color: #053C5E;
    padding: 8px;
}

.Content {
    max-width: 1042px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Logo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: auto;
}

.Name {
    font-family: 'Roboto Slab', serif;
    font-size: 22px;
    color: #E3AA26;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    margin-left: 10px;
}

.Name .FirstLine {
    color: white;
}

.Name .FirstLetters {
    font-size: 28px;
}

@media only screen and (max-width: 1100px) {
    .Content {
        justify-content: space-evenly;
    }

    .Logo .Name {
        display: none;
    }

    .NavBarContainer {
        height: 120px;
    }

    .NavBarContainer img {
        height: 105px;
    }
}

@media only screen and (max-width: 768px) {
    .Menu {
        display: none;
    }
}